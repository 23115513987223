.no-data-message {
  font-size: 2rem;
  color: #1a73e8; /* Same color as header background */
  text-align: center;
  margin: 40px auto; /* Centers the message and adds space above */
  line-height: 1.5;
  max-width: 400px; /* Limits the width */
}

.no-data-message2 {
  font-size: 1.25rem;
  color: #1a73e8; /* Same color as header background */
}
