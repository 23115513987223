/* src/pages/PropQuest.css */

.proposition-container {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
}

.proposition-header {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 10px;
}

.message-box {
  display: flex;
  margin-top: 20px;
  max-height: 400px;
  overflow-y: auto;
  padding: 10px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.message {
  padding: 10px;
  border-radius: 8px;
  margin-bottom: 10px;
  width: fit-content;
  max-width: 80%;
}

.chatform {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.user {
  align-self: flex-end;
  background-color: #d1e7ff;
  color: #333;
}

.assistant {
  align-self: flex-start;
  background-color: #e8f5e9;
  color: #333;
}

.chat-input-area {
  display: flex;
  align-items: center; /* Align button and input vertically */
  margin-top: 10px;
}

.chat-input-area input[type="text"] {
  flex: 1;
  padding: 10px;
  border-radius: 24px;
  border: 1px solid #ddd;
  margin-right: 8px;
  font-size: 1rem;
}

.chat-input-area button {
  padding: 10px 16px;
  background-color: #3b5998;
  border: none;
  border-radius: 24px;
  color: white;
  font-weight: bold;
  cursor: pointer;
  white-space: nowrap; /* Prevent button text from wrapping */
}

.chat-input-area button:hover {
  background-color: #344e86;
}
