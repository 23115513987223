/* src/App.css */

body {
  font-family: Arial, sans-serif;
  line-height: 1.6;
  color: #333;
  background-color: #f0f4f8;
  margin: 0;
}

.container {
  max-width: 950px; /* New max width */
  margin: 0 auto;
  padding: 20px;
  box-sizing: border-box;
}

header {
  background-color: #1a73e8;
  color: #ffffff;
  padding: 20px 0;
  margin-bottom: 20px;
}

header h1 {
  font-size: 4rem;
  font-weight: bold;
  color: #ffffff;
  text-align: center;
  margin: 0;
  padding: 10px 0;
}

header nav {
  display: flex;
  justify-content: center;
  gap: 20px;
}

header nav a {
  color: #ffffff;
  font-weight: bold;
  text-decoration: none;
  font-size: 1.1rem;
}

header nav a:hover {
  text-decoration: underline;
}

/* Button styles for more space */
button {
  font-size: 1rem;
  padding: 10px 20px;
  border-radius: 8px;
  background-color: #1a73e8;
  color: white;
  border: none;
  cursor: pointer;
}

button:hover {
  background-color: #1669c1;
}

/* Ballot table adjustments */
.ballot-table {
  width: 100%;
  margin: 20px 0;
  border-collapse: separate;
  border-spacing: 0 10px;
}

.ballot-table th {
  background-color: #f0f0f0;
  padding: 15px;
  text-align: left;
  font-weight: bold;
}

.ballot-table td {
  background-color: #ffffff;
  padding: 15px;
  border-radius: 5px;
  vertical-align: middle;
}

/* Text input and form in PropQuest */
input[type="text"] {
  width: 100%;
  padding: 12px;
  margin-top: 10px;
  margin-bottom: 10px;
  border: 1px solid #cccccc;
  border-radius: 6px;
  font-size: 1rem;
}

form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

h3 {
  color: #1a73e8;
  margin-bottom: 10px;
}


.assistant, .user {
  width: 100%;
  max-width: 850px;
  padding: 12px;
  margin: 5px 0;
  border-radius: 8px;
  word-wrap: break-word;
}

.assistant {
  background-color: #e3f2fd;
  color: #333;
}

.user {
  background-color: #bbdefb;
  color: #333;
}

